const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://qpaarvdj7i.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://e1bbi2on1g.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://pgu207ckpd.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.1',
    HOSTNAME: 'https://locations.pas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.pas.forwoodsafety.com',
    WEBSOCKET: 'wss://cktts421p0.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;